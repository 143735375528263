import Network from '@/services/network'
import { ref } from 'vue'

export default function handleJournal() {
    const network = new Network;
    const loading = ref(false)

    const fetchJournals = (query) => {
      return network.api('get', '/reports/ledger-master' + query);
    }

    const fetchJournal = (ledgerMasterId) => {
      return network.api('get', '/ledgers/'+ledgerMasterId);
    }

    const createJournal = (data) => {
      return network.api('post', '/ledgers', data);
    }

    const editJournal = (id,data) => {
      return network.api('post', '/ledgers/'+id, data);
    }

    const fetchAccountHeads = (companyId, cash_bank_hide='') => {
      return network.api('get', `/account-heads?company_id=${companyId}&cash_bank_hide=${cash_bank_hide}`);
    }

    const fetchUploadedJournals = (query) => {
      return network.api('get', '/ledgers/upload/list'+ query);
    }

    const uploadJournal = (data) => {
      return network.api('post', '/ledgers/upload', data);
    }

    const fetchSheet = (id, query) => {
      return network.api('get', '/ledgers/upload/'+id+query);
    }

    const updateIndividual = (id, data) => {
      return network.api('post', '/ledgers/update/voucher/'+id, data);
    }

    const storeIndividual = (id, data) => {
      return network.api('post', '/ledgers/store/voucher/'+id, data);
    }
    
    const removeUploaded = (id, companyId) => {
      return network.api('delete', `/ledgers/delete/voucher/${id}?company_id=${companyId}`);
    }
    

    return {
        fetchJournals,
        removeUploaded,
        storeIndividual,
        fetchSheet,
        updateIndividual,
        uploadJournal,
        fetchUploadedJournals,
        fetchJournal,
        createJournal,
        fetchAccountHeads,
        editJournal,
        loading
    }
}